<template>
    <div id="shop-page" v-show="pageLoaded">
        <div id="shop-header">
            <h1>Shop</h1>
        </div>
        <img id="hiddenimgPageLoad" src="~@/assets/merch-booth.jpg" @load="onImgLoad" alt="">
        <div id="add-shop-container" v-if="ulog">
            <v-btn
                id="add-shop-btn" 
                color="error"
                @click="addProductOverlay = !addProductOverlay"
                >
                Add Product
            </v-btn>
        </div>
        <div id="shop-subtitle">
            <h2>Your purchase will support our work and spread a message of liberation for all!</h2>
        </div>
        <div id="product-section">
            <ExplorePageTitles title="Products"/>
            <div id="product-container">
                <div id="shop-item-display" v-for="item in shopItems" :key="item.id">
                    <ShopItem :item="item"/>
                </div>
            </div>
        </div>
        <v-overlay
            id="create-new-product-overlay"
            v-if="ulog"
            :z-index="zIndex"
            :value="addProductOverlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="create-product-form-container">
                <CreateProductForm @changeOverlay="addProductOverlay = !addProductOverlay"/>
            </div>
        </v-overlay>
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import cookies from 'vue-cookies'
import ShopItem from '../components/ShopItem.vue'
import ExplorePageTitles from '../components/ExplorePageTitles.vue';
import CreateProductForm from '../components/CreateProductForm.vue';

    export default {
        name: "Shop",
        components: {
            ShopItem,
            ExplorePageTitles,
            CreateProductForm,
        },
        computed: {
            sessionCookie() {
                return cookies.get('_casLt') 
            },
        },
        beforeMount() {
            this.getShopItems();
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true
            }
        },
        data() {
            return {
                pageLoaded: false,
                shopItems: [],
                ulog: false,
                pageOverlayFlow: false,
                addProductOverlay: false,
                zIndex: 98,
                opacity: 1,
                absolute: true,
            }
        },
        methods: {
            getShopItems() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/shop_products',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    this.shopItems = response.data;
                }).catch(() => {
                    router.push('/donate');
                });
            },
            onImgLoad() {
                this.pageLoaded = true;
            }
        }
    }
</script>

<style lang="scss" scoped>

    #shop-page {
        width: 100vw;
        background-color: white;
        min-height: 100vh;
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);
    }

    #hiddenimgPageLoad {
        display: none;
    }

    #shop-header {
        width: 100%;
        height: 40vh;
        display: grid;
        justify-items: center;
        align-items: center;
        position: relative;
        text-align: center;
        background-color: black;
        
        h1 {
            align-self: center;
            color: #d57f1e;
            font-family: "Helvetica";
            font-size: 5em;
            position: relative;
        }
    }

    #shop-header::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('~@/assets/merch-booth.jpg');
        background-position: center;
        background-size: cover;
        opacity: 0.5;
    }

    #add-shop-container {
        width: 100%;
        padding: 15px 20px 0 20px;
        display: grid;
        justify-items: end;

        #add-shop-btn {
            width: fit-content;
            padding: 15px 20px;
        }
    }

    #shop-subtitle {
        width: 100%;
        display: grid;
        justify-items: center;
        align-items: center;
        padding: 60px 15px;
        font-family: "Helvetica";

        h2 {
            text-align: center;
            font-size: 1em;
        }
    }

    #product-section {

        #product-container {
            display: grid;
            justify-items: center;
        }
    }

    #create-new-product-overlay {
        display: grid;
        min-height: 100vh;
        height: fit-content;

        #create-product-form-container {
            width: 90vw;
            margin-bottom: 4vh;
        }
    }

@media screen and (min-width: 600px) {
    #shop-page {
        margin-bottom: 150px; //account for absolute footer height

        #shop-header {
            
            h1 {
                font-size: 7em;
            }
        }

        #shop-subtitle {
            padding: 60px 40px;

            h2 {
                font-size: 1.5em;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #shop-page {
        max-width: 1350px;
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;

        #shop-header {
            height: 40vh;
            
            h1 {
                font-size: 9em;
            }
        }

        #shop-header::before {
            background-image: url('~@/assets/merch-booth-cropped.jpg');
        }

        #product-section {

            #product-container {
                grid-template-columns: 50% 50%;
                grid-template-rows: auto;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    #shop-page {

        #shop-header {
            height: 50vh;
            
        }
    }
}
</style>